<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-form @submit.prevent="addUser" ref="form">
        <v-card>
          <v-card-title class="text-h5">Add Category</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Name"
                    hide-details="auto"
                    v-model="form.name"
                    :error-messages="form.$getError('name')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" right>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text color="green" v-bind="attrs" @click="snackbar.show = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import QuestionCategory from '@/models/QuestionCategory'
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value,
      form: new Form({
        name: null
      }),
      activePicker: null,
      date: null,
      snackbar: {
        show: false,
        message: null
      }
    }
  },
  methods: {
    addUser() {
      this.form.$busy = true
      this.form.$clearErrors()
      new QuestionCategory({
        creator_id: this.creator.id,
        ...this.form.$data()
      })
        .save()
        .then(() => {
          this.dialog = false
          this.showSnackbar('Category was successfully added!')
          this.$refs.form.reset()
          this.form.$clearErrors()
          this.$emit('event')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.$clearErrors()
    },
    showSnackbar(message) {
      this.snackbar.message = message
      this.snackbar.show = true
    }
  },
  computed: {
    ...mapState({
      creator: state => state.auth.user
    })
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  }
}
</script>
